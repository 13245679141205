import React from 'react'
import { MDBCol } from 'mdbreact'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhoneAlt,
  faFax,
  faEnvelope,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons'

class CardIconsOffices extends React.Component {
  render() {
    return (
      <>
        <MDBCol lg="6" md="12" className="pb-30 d-flex">
          <div className="card flex-fill outline card-hover">
            <div
              className="card-body d-flex flex-row align-items-center"
              style={{ minHeight: '150px', maxHeight: '150px' }}
            >
              <div
                style={{
                  minWidth: '85px',
                  maxWidth: '85px',
                }}
              >
                <Img
                  fixed={this.props.imageUrl}
                  className="mr-1"
                  alt={this.props.imageAltText}
                />
              </div>
              <div className="d-flex align-items-center">
                <h3 className="font-alt font-w-700 letter-spacing-1 title-sm-small title-medium pl-lg-5 pl-3">
                  {this.props.depttitle}
                </h3>
              </div>
            </div>

            <div className="card-body-prods">
              <ul className="fa-ul text-medium">
                {this.props.tel && (
                  <>
                    <li className="pb-3">
                      <span className="fa-li">
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="ml-2 mr-5 va-m"
                        />
                      </span>
                      {this.props.tel}
                    </li>
                  </>
                )}

                {this.props.tel1 && (
                  <>
                    <li className="pb-3">
                      <span className="fa-li">
                        <FontAwesomeIcon
                          icon={faPhoneAlt}
                          className="ml-2 mr-5 va-m"
                        />
                      </span>
                      {this.props.tel1}
                    </li>
                  </>
                )}

                {this.props.fax && (
                  <>
                    <li className="pb-3">
                      <span className="fa-li">
                        <FontAwesomeIcon
                          icon={faFax}
                          className="ml-2 mr-5 va-m"
                        />
                      </span>
                      {this.props.fax}
                    </li>
                  </>
                )}

                {this.props.email && (
                  <>
                    <li className="pb-3">
                      <span className="fa-li">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="ml-2 mr-5 va-m"
                        />
                      </span>
                      <a
                        href={'mailto:' + this.props.email}
                        className="effect"
                        rel="noopener"
                        target="_blank"
                      >
                        {this.props.email}
                      </a>
                    </li>
                  </>
                )}

                {this.props.suptext && (
                  <>
                    <li className="pb-3">
                      <span className="fa-li">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="ml-2 mr-5 va-m"
                        />
                      </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.suptext,
                        }}
                      />
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </MDBCol>
      </>
    )
  }
}

export default CardIconsOffices
